.nav-tabs {
  border-bottom: 1px solid $base-color;
  .nav-link {
    background-color: #29a1d6;
    color: white;
    font-size: 21px;
    flex: 1 1 32%;
    padding: 15px;
    border-radius: 0;
    &.half {
      @media (min-width: 576px) {
        border-radius: 10px 10px 0 0;
        flex: 0 1 49%;
      }
    }
    @media (min-width: 576px) {
      border-radius: 10px 10px 0 0;
      flex: 0 1 32%;
      // padding: 60px;
    }
    &.active {
      background-color: $base-color;
      color: white;
      border: none;
    }
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
}
#pills-tabContent {
  border: 1px solid $base-color;
  border-radius: 0 0 10px 10px;
  padding: 15px;
  @media (min-width: 576px) {
    padding: 60px;
  }
  .tabs-title {
    font-size: 24px;
    color: #d83f20;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba($base-color, 0.3);
    margin-bottom: 1rem;
  }
  .color-blue {
    color: $base-color;
  }
  .color-pink {
    color: $color-3;
  }
  .color-lt-blue {
    color: $base-lt-color;
  }
  .download-link {
    color: #333333;
    display: block;
    border-bottom: 1px solid rgba($base-color, 0.3);
    text-decoration: none;
    padding: 8px 0;
    margin-bottom: 1rem;
    &:hover {
      color: $base-color;
      text-decoration: none;
    }
    img {
      margin-right: 0.5rem;
      width: 16px;
      height: 18px;
    }
  }
  .border-rounded-lt-blue {
    border: 2px solid $base-lt-color;
    border-radius: 10px;
    position: relative;
    &.border-rounded-arrow {
      &:before {
        content: "\f063";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translateX(-50%);
        color: $base-lt-color;
      }
      &:after {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        position: absolute;
        left: 50%;
        bottom: -2px;
        background-color: white;
        z-index: 0;
        transform: translateX(1px);
      }
    }
  }
  .card {
    height: 100%;
    border: none;
    &.card-blue {
      .card-title {
        background-color: $base-color;
      }
      .card-content {
        border: 1px solid $base-color;
      }
    }
    &.card-pink {
      .card-title {
        background-color: $color-3;
      }
      .card-content {
        border: 1px solid $color-3;
      }
    }
    &-title {
      padding: 0.5rem;
      text-align: center;
      color: white;
      margin-bottom: 0;
      height: 100%;
      border-radius: 10px 10px 0 0;
      p {
        font-size: 22px;
        margin-bottom: 0;
        span {
          font-size: 18px;
          margin-bottom: 0;
        }
      }
    }
    &-content {
      padding: 0.5rem;
      text-align: center;
      height: 100%;
      border-radius: 0 0 10px 10px;
      ul {
        text-align: left;
        margin-bottom: 0;
        // list-style: none;
        // li {
        //   display: inline-block;
        //   &:before {
        //     content: ".";
        //   }
        // }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  button {
    &[data-target="#collapseOne"] {
      color: #840ea5;
      border-bottom: 1px dashed #000;
    }
    &[data-target="#collapseTwo"] {
      color: #d27500;
      border-bottom: 1px dashed #000;
    }
    &[data-target="#collapseThree"] {
      color: #009875;
      border-bottom: 1px dashed #000;
    }
  }
  table:not(.search_result_table) {
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    tbody {
      th {
        background-color: #29a1d6;
        color: white;
        text-align: center;
        vertical-align: middle;
        width: 100px;
      }
      td {
        border: 1px solid;
        border-color: #29a1d6 #29a1d6 transparent #29a1d6;
      }
      tr {
        &:first-child {
          th {
            border-radius: 10px 0 0 0;
          }
          td {
            border-radius: 0 10px 0 0;
          }
        }
        &:last-child {
          th {
            border-radius: 0 0 0 10px;
          }
          td {
            border-radius: 0 0 10px 0;
            border-bottom: 1px solid #29a1d6;
          }
        }
      }
    }
  }

  .search_result_table {
    tbody {
      td {
        vertical-align: middle;
        &:before {
          display: block;
          width: 150px;
          padding-left: 0;
          @media (min-width: 992px) {
            display: none;
          }
        }
      }
    }
  }
}
