header {
  border-bottom: 2px solid $base-color;
  .navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    &-brand {
      padding: 0;
      margin: 0;
      display: block;
      overflow: hidden;
      width: 129px;
      height: 48px;
      position: relative;
      z-index: 2;
      h1 {
        text-indent: 101%;
        margin-bottom: 0;
      }

      img {
        width: 129px;
        height: 48px;
      }
    }

    &-nav {
      position: static;
      width: 100%;
      display: flex;
      justify-content: center;

      .nav-item {
        margin: 0 10px;
        padding: 0.5rem;
        .nav-link {
          color: $d-color;
          text-align: center;
          border-bottom: 1px solid transparent;
          transition: background-color 0.3s;
        }

        .dropdown-content {
          position: static;
          width: 100%;
          background-color: white;
          left: 0;
          top: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;
          height: 0;
          overflow: hidden;
          opacity: 0;
          transition: 0.3s;
          flex-direction: column;
          a {
            padding: 1rem;
            color: $d-color;
            transition: 0.3s;
            width: 100%;
            text-align: center;
            border-bottom: 1px dashed $base-color;

            &:hover {
              color: $l-color;
              background-color: $base-color;
              text-decoration: none;
            }
          }
        }

        &:hover {
          .nav-link {
            border-bottom: 1px solid $base-color;
            @media (min-width: 992px) {
              color: white;
              border-bottom: none;
            }
          }
          @media (min-width: 992px) {
            background-color: $base-color;
          }
          .dropdown-content {
            opacity: 1;
            visibility: visible;
            // height: auto;
            height: 100%;
            transition: 0.3s;
            a {
              padding: 1rem;
              color: $d-color;
              transition: 0.3s;
              position: relative;
              @media (min-width: 992px) {
                border: 1px solid $base-color;
                border-radius: 5px;
                color: $base-color;
                padding: 0.5rem 30px 0.5rem 0.5rem;
                margin: 1rem 0.5rem;
                &:before {
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 500;
                  content: "\f054";
                  position: absolute;
                  right: 15px;
                  bottom: 50%;
                  transform: translateY(50%);
                  transition: 0.3s;
                  color: #1987c1;
                }
                &:hover {
                  height: 100%;
                }
              }

              &:hover {
                color: $l-color;
                background-color: $base-color;
                text-decoration: none;
                height: auto;
                &:before {
                  color: white;
                }
              }
            }
          }
        }
      }
      @include lg {
        position: absolute;
        right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
        .nav-item {
          padding: 12px 0.5rem;
          .dropdown-content {
            position: absolute;
            flex-direction: row;
            z-index: 2;
            a {
              width: auto;
              padding: 1rem;
              color: $d-color;
              transition: 0.3s;
              text-align: center;
              border-bottom: none;
            }
          }
        }
      }
    }

    &-toggler-icon {
      i {
        font-size: $xxl-font;
        color: $base-color;
      }
    }
  }

  .header_link_group {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    a {
      width: 30px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid $base-color;
      border-radius: 50%;
      display: block;
      color: $base-color;
      font-size: $s-font;
      line-height: 30px;
      text-align: center;
      transition: 0.3s;
      margin: 5px;
      &:hover {
        background-color: $base-color;
        color: $l-color;
      }
      .fa-phone {
        transform: rotateY(180deg);
      }
    }
  }
}
