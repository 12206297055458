footer {
	background-color: $base-color;
	padding: 25px 0;
	font-size: 0.875rem;
	letter-spacing: 1px;

	.footer {
		&_links {
			display: flex;
			align-items: center;
			justify-content: left;
			flex-wrap: wrap;

			a {
				color: $l-color;
				display: block;
				font-size: $s-font;
				text-align: center;
				margin-bottom: 6px;
				padding: 0 6px;
			}

			@include md {
				justify-content: center;

				a {
					padding: 0 30px;
					position: relative;

					&::after {
						content: "";
						width: 1px;
						background-color: #fff;
						height: 15px;
						position: absolute;
						transform: translateY(-50%);
						top: 50%;
						right: 0;
					}

					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}
			@include lg {
				a {
					padding: 0 45px;
				}
			}
		}

		&_content {
			color: $l-color;
			list-style: none;
			display: flex;
			margin: 0;
			padding: 0;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 4px;
			flex-wrap: wrap;
			flex-direction: column;

			li {
				margin: 0;
			}

			@include md {
				flex-direction: row;
				justify-content: center;
				align-items: center;

				li {
					margin: 0 10px;
				}
			}
		}

		&_copy {
			color: $l-color;
			text-align: left;

			@include md {
				text-align: center;
			}
		}
	}
}
