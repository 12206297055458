@media (min-width:576px) {}

@media screen and (max-width:768px) {
    #footer .conttxt {
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 25px 0 25px 0;
        color: #fff;
        text-align: center;
        font-size: 12px;
        line-height: 25px;
    }
}

@media (min-width:992px) {}

@media (min-width:1024px) {}

@include screenmin(1200px) {}

;