// ----- PC列印樣式修正
@mixin screenmin($res-min) {
  @media screen and (min-width: $res-min) and (all) {
    @content;
  }

  @media print {
    @content;
  }
}
// ------ text-align: justify

@mixin text-justify {
  text-align: justify;
  text-justify: inter-ideograph;
  -ms-text-justify: inter-ideograph; //IE9//
  -moz-text-align-last: justify; //Firefox//
  -webkit-text-align-last: justify; //Chrome//
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

// ------ 圓角
@mixin Radius($radius) {
  -ms-border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

// ------ 線性漸層
@mixin GL($args...) {
  background: -o-linear-gradient($args);
  background: -moz-linear-gradient($args);
  background: -webkit-linear-gradient($args);
  background: -ms-linear-gradient($args);
  background: linear-gradient($args);
  -pie-background: linear-gradient($args);
}

// ------ 徑向漸層
@mixin GR($inner...) {
  background: -webkit-gradient($inner);
  background: -webkit-radial-gradient($inner);
  background: -moz-radial-gradient($inner);
  background: -o-radial-gradient($inner);
}

// ------ 區塊陰影
@mixin shadow($shadow...) {
  -webkit-box-shadow: ($shadow);
  -ms-box-shadow: ($shadow);
  box-shadow: ($shadow);
}

// ------ 漸變時間差
@mixin TS($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -ms-transform: $transition;
  transition: $transition;
}

@mixin TS-d($Seconds) {
  -webkit-transition-delay: $Seconds;
  -moz-transition-delay: $Seconds;
  -o-transition-delay: $Seconds;
  transition-delay: $Seconds;
}

// ------ 變形屬性
@mixin TF($transform) {
  -moz-transform: ($transform);
  -webkit-transform: ($transform);
  -o-transform: ($transform);
  -ms-transform: ($transform);
  transform: ($transform);
}

// ------ 變形軸心
@mixin TF-o($transform-origin) {
  -moz-transform-origin: ($transform-origin);
  -webkit-transform-origin: ($transform-origin);
  -o-transform-origin: ($transform-origin);
  -ms-transform-origin: ($transform-origin);
  transform-origin: ($transform-origin);
}

// ------ 動畫
@mixin animation($animate) {
  -webkit-animation: ($animate);
  /* Safari 4+ */
  -moz-animation: ($animate);
  /* Fx 5+ */
  -o-animation: ($animate);
  /* Opera 12+ */
  animation: ($animate);
  /* IE 10+, Fx 29+ */
}

// ------ 動畫影格
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  // @-ms-keyframes #{$name} {
  //    @content;
  // }
  @keyframes #{$name} {
    @content;
  }
}

// ------ 濾鏡效果
@mixin filter-display($filter) {
  -webkit-filter: ($filter);
  /* Safari 6.0 - 9.0 */
  filter: $filter;
}

// ------ 變形形狀
@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  clip-path: $clip;
}

// ------ 預設表單樣式
@mixin appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// ------ 預設表單下拉選單箭頭
@mixin selection {
  @include appearance;

  &::selection {
    display: none;
  }

  &::-moz-selection {
    display: none;
  }

  &::-ms-expand {
    display: none;
  }
}

@mixin placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  &::placeholder {
    @content;
  }

  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    @content;
  }

  /* Microsoft Edge */
  &::-ms-input-placeholder {
    @content;
  }
}

@mixin WM($mode) {
  -webkit-writing-mode: $mode;
  writing-mode: $mode;
}

// ------ FlexboxLayout
@mixin flexbox($display: flex, $direction: row, $wrap: nowrap) {
  @if $display== "flex" {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  } @else if $display== "inline-flex" {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  -webkit-flex-flow: $direction $wrap;
  -moz-flex-flow: $direction $wrap;
  -ms-flex-flow: $direction $wrap;
  -o-flex-flow: $direction $wrap;
  flex-flow: $direction $wrap;

  * {
    -ms-flex: 0 1 auto;
  }
}

@mixin justify-content($layout) {
  -webkit-justify-content: $layout;
  -moz-justify-content: $layout;
  -o-flex-justify-content: $layout;
  justify-content: $layout;

  @if $layout== "flex-start" {
    -ms-flex-pack: start;
  } @else if $layout== "flex-end" {
    -ms-flex-pack: end;
  } @else if $layout== "center" {
    -ms-flex-pack: center;
  } @else if $layout== "space-between" {
    -ms-flex-pack: justify;
  } @else if $layout== "space-around" {
    -ms-flex-pack: distribute;
  }
}

@mixin align-item($layout) {
  -webkit-align-items: $layout;
  align-items: $layout;

  @if $layout== "flex-start" {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $layout== "flex-end" {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else if $layout== "center" {
    -webkit-box-align: center;
    -ms-flex-align: center;
  } @else if $layout== "baseline" {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
  } @else if $layout== "stretch" {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
  }
}

// ----------------   IE10 替代數值 ----------------
// flex-start ----> start
// flex-end ----> end
// center ----> center
// space-between ----> justify
// space-around ----> distribute
// ----------------  IE 100%滿版 替代數值 ----------------
// -ms-flex:0 1 auto

// *,
// *:active,
// *:focus,
// *:hover,
// *:link,
// *:visited {
//   -o-transition: background-color 0.2s linear, border-color 0.1s linear;
//   -webkit-transition: background-color 0.2s linear, border-color 0.1s linear;
//   -moz-transition: background-color 0.2s linear, border-color 0.1s linear;
//   transition: background-color 0.2s linear, border-color 0.1s linear;
// }
// ------ 色系 (lt:亮, dk:暗)
$d-color: #333;
$l-color: #fff;
//基礎色：藍色
$base-color: #1987c1;
$base-lt-color: #7fc2e6;
$color-1: #eeb93e;
$color-2: #63bfad;
$color-3: #e5806c;
$color-4: #86cfda;
$base-bg: #82d7ff;
// ------ 全網字級定義
$xs-font: 0.8rem; // ---- 內文15px
$s-font: 1rem; // ---- 內文
$m-font: 1.2rem; // ---- 副副標h3
$l-font: 1.5rem; // ---- 副標
$xl-font: 1.8rem; // ---- 副標
$xxl-font: 2rem; // ---- 主
// ------ 自定義FontAwesome
$icon: "Font Awesome 5 Free";
$icon_2: icomoon;

@mixin fa-icon($num, $font-face) {
  content: $num;
  font-family: $font-face;

  @if $font-face==$icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    font-style: normal;
  } @else if $font-face==$icon_2 {
  }
}

/* 思源黑體 */
// @import url(https://fonts.googleapis.com/earlyaccess/cwtexhei.css);
/* font-family: 'cwTeXHei',sans-serif; */

// @import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
/* font-family:'Noto Sans TC', sans-serif; */

/* 麗粗宋 */
// @import url('https://fonts.googleapis.com/css?family=Noto+Serif+TC:400,900');
/* font-family: 'Noto Serif TC', serif; */

html {
  font-size: 16px;
}

// body {
//   font-family: arial, '微軟正黑體', sans-serif;
// }
h1,
h2,
h3 {
  font-weight: 400;
}

h4,
h5,
h6,
p,
span,
li {
  font-weight: 350;
}

main {
  min-height: calc(100vh - 64px);
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin acItem($color) {
  border: 1px solid $color;
  background-color: $color;
}
