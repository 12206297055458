.carousel {
  &-indicators {
    justify-content: center;
    margin: 0.5rem 0;
    li {
      border: 0;
      background: #fff;
      opacity: 1;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      // padding: 5px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-left: 10px;
      &.active {
        border-radius: 30px;
        width: 30px;
      }
    }
    @include md {
      justify-content: flex-end;
      margin: 1rem 0;
      li {
        border: 0;
        background: #fff;
        opacity: 1;
        display: block;
        height: 15px;
        width: 15px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        // padding: 5px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin-left: 20px;
        &.active {
          border-radius: 30px;
          width: 60px;
        }
      }
    }
  }
  &-item {
    img {
      // object-fit: cover;
      // height: 200px;
      @include md {
        // height: 600px;
        // object-fit: cover;
      }
    }
  }
  .js-img {
    // height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include md {
      // height: 600px;
    }
  }
}
