#pagination-container {
  .arrow-left {
    width: 7px;
    height: 7px;
    transform: scale(-1);
    margin-bottom: 4px;
  }

  .arrow-right {
    width: 7px;
    height: 7px;
    margin-bottom: 4px;
  }
}

.news {
  background-color: rgba($base-bg, $alpha: 0.2);
  padding: 2rem 0;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  &_moreBtn {
    background-color: $base-color;
    padding: 5px 20px;
    color: $l-color;
    transition: 0.3s;
    text-align: center;

    &:hover {
      text-decoration: none;
      color: $l-color;
      background-color: darken($base-color, $amount: 10%);
    }
  }

  &_link {
    display: flex;
    border-top: 1px solid $base-lt-color;
    width: 100%;
    color: $d-color;
    transition: 0.3s;

    span {
      display: block;
      padding: 15px 10px;
      flex-shrink: 1;
    }

    &:hover {
      background-color: $base-lt-color;
      color: $l-color;
      text-decoration: none;
    }
  }

  &_banner {
    background-color: #30aae5;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.hope {
  padding: 2rem 0;
  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }
  &_banner {
    background-color: #30aae6;
    margin-bottom: 30px;
    overflow: hidden;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      position: relative;
      z-index: 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: -10px;
      z-index: 0;
    }
  }
}

.memorabillia {
  padding: 2rem 0;
  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }
  &_banner {
    background-color: #30aae6;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      transform-origin: right bottom;
      transform: scale(0.6) translateX(10px);
      @include lg {
        transform: scale(1);
      }
    }
  }

  &_table {
    thead {
      // display: none;

      tr {
        th {
          color: $base-color;
          font-weight: normal;
          border-top: none;
          border-bottom: 1px solid lighten($color: $base-color, $amount: 40%);

          &:nth-child(1) {
            width: 100px;
          }

          &:nth-child(2) {
            width: 250px;
          }

          &:nth-child(3) {
            width: 200px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid lighten($color: $base-color, $amount: 40%);

        td {
          // display: flex;
          // align-items: baseline;
          // display: block;
          position: relative;
          // padding: 5px 10px 5px 130px;
          // padding-left: 100px;
          border-top: none;

          // &::before {
          //   content: attr(data-th);
          //   display: block;
          //   width: 125px;
          //   min-width: 120px;
          //   color: $base-color;
          //   margin-right: 1em;
          //   padding: 5px 10px;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          // }
        }
      }
    }

    @include lg {
      thead {
        display: table-header-group;
      }

      tbody {
        tr {
          td {
            padding: 0.75rem;
            display: table-cell;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.activity {
  background-color: #fff;
  padding: 2rem 0;

  &_item {
    display: flex;
    align-items: center;
    @include acItem($base-lt-color);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
    &:hover {
      text-decoration: none;
    }

    &_img {
      height: 200px;
      background-image: url(./../img/index/index_1.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      flex: 0 0 50%;
      max-width: 50%;

      @include lg {
        height: 300px;
      }
    }

    &_link {
      color: white;
      text-decoration: underline;

      &:hover {
        color: white;
        text-decoration: underline;
      }
    }

    &_text {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 20px;
      color: #fff;

      h3 {
        font-size: $m-font;
        line-height: 1.5;
      }

      p {
        font-size: $s-font;
        margin-bottom: 0;
      }
    }
  }

  .row {
    .item_group:first-child {
      .activity_item:first-child {
        @include acItem($color-3);
        &:hover {
          transition: all 0.3s ease-out;
          @include acItem(darken($color-3, 5%));
          box-shadow: 4px 4px 5px rgba(#9e9e9e, 0.3);
        }
        .activity_item_img {
          background-image: url(./../img/index/index_1.jpg);
        }
      }

      .activity_item:last-child {
        @include acItem($color-1);
        flex-flow: row-reverse;
        &:hover {
          transition: all 0.3s ease-out;
          @include acItem(darken($color-1, 5%));
        }
        .activity_item_img {
          background-image: url(./../img/index/index_2.jpg);
        }
      }
    }

    .item_group:last-child {
      .activity_item:first-child {
        @include acItem($base-lt-color);
        &:hover {
          transition: all 0.3s ease-out;
          @include acItem(darken($base-lt-color, 10%));
          box-shadow: 4px 4px 5px rgba(#9e9e9e, 0.3);
        }
        .activity_item_img {
          background-image: url(./../img/index/index_4.jpg);
        }
      }

      .activity_item:last-child {
        @include acItem($color-2);
        flex-flow: row-reverse;
        &:hover {
          transition: all 0.3s ease-out;
          @include acItem(darken($color-2, 10%));
          box-shadow: 4px 4px 5px rgba(#9e9e9e, 0.3);
        }
        .activity_item_img {
          background-image: url(./../img/index/index_3.jpg);
        }
      }
    }
  }
}

.simple-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.light-theme {
  a,
  span {
    border: none;
    box-shadow: none;
    color: $base-color;
    background: transparent;
    font-size: 1rem;
    opacity: 0.5;
    transition: 0.3s;

    &:hover {
      color: $base-color;
      opacity: 1;
    }
  }

  .current {
    color: $base-color;
    background: $l-color;
    border-color: transparent;
    box-shadow: none;
    opacity: 1;
  }
}

.breadcrumb_outer {
  margin-left: auto;
  margin-bottom: 15px;
  margin-top: 0;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;

    img {
      width: 22px;
      height: 22px;
      transform: translateY(-3px);
    }

    &-item {
      position: relative;
      color: lighten($color: $base-color, $amount: 20%);
      a {
        color: lighten($color: $base-color, $amount: 20%);

        &:hover {
          color: $base-color;
          text-decoration: none;
        }
      }

      &.active {
        color: $base-color;
      }
    }
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 1.5rem;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: " ";
    position: absolute;
    background-image: url(./../img/inpage/arrow.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 5px;
    height: 12px;
    left: 10px;
    bottom: 50%;
    transform: translateY(50%);
  }

  @include md {
    // margin-bottom: 0;
    margin: 15px;
  }
}

.inpageArea {
  h4,
  p,
  img {
    margin-bottom: 2rem;
  }

  .btn {
    background-color: $base-color;
    color: $l-color;
    padding: 0.5rem 1.5rem;
    border-radius: 10px;

    &:hover {
      background-color: darken($color: $base-color, $amount: 10%);
    }
  }
}

.btnGroup {
  margin-top: 30px;

  // .btn-index {
  // 	height: 70px;
  // 	@include md {
  // 		height: 80px;
  // 	}
  // }
  a {
    padding: 10px;
    padding-right: 20px;
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
    color: $base-color;
    border: $base-color 1px solid;
    display: block;
    // display: flex;
    // align-items: center;
    width: 100%;
    border-radius: 5px;
    position: relative;
    transition: 0.3s;
    // margin-bottom: 15px;
    height: 100%;
    text-overflow: ellipsis;

    &:after {
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      content: "\f054";
      position: absolute;
      right: 10px;
      bottom: 50%;
      transform: translateY(50%);
      transition: 0.3s;
      color: $base-color;
    }

    &:hover {
      background-color: $base-color;
      color: $l-color;
      text-decoration: none;

      &:after {
        color: $l-color;
      }
    }

    @include md {
      padding: 20px;
      padding-right: 30px;

      &:after {
        font-family: "Font Awesome 5 Pro";
        font-weight: 500;
        content: "\f054";
        position: absolute;
        right: 20px;
        bottom: 50%;
        transform: translateY(50%);
        transition: 0.3s;
        color: $base-color;
      }
    }
  }
}

.donate {
  &_table {
    thead {
      display: none;

      tr {
        th {
          color: $base-color;
          font-weight: normal;
          border-top: none;
          border-bottom: 1px solid lighten($color: $base-color, $amount: 40%);

          &:nth-child(1) {
            width: 100px;
          }

          &:nth-child(2) {
            width: 250px;
          }

          &:nth-child(3) {
            width: 200px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid lighten($color: $base-color, $amount: 40%);

        td {
          display: block;
          padding: 10px;
          border-top: none;
          position: relative;
          padding-left: 120px;

          &::before {
            content: attr(data-th);
            display: block;
            width: 120px;
            color: $base-color;
            margin-right: 1em;
            padding: 10px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    @include lg {
      thead {
        display: table-header-group;
      }

      tbody {
        tr {
          td {
            padding: 0.75rem;
            display: table-cell;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.love {
  background-color: rgba($base-bg, $alpha: 0.2);
  padding: 2rem 0;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  h4 {
    color: #1987c1;
  }

  p {
    margin-bottom: 10px;
  }

  &_moreBtn {
    background-color: $base-color;
    padding: 5px 20px;
    color: $l-color;
    transition: 0.3s;
    text-align: center;

    &:hover {
      text-decoration: none;
      color: $l-color;
      background-color: darken($base-color, $amount: 10%);
    }
  }

  &_link {
    display: flex;
    border-top: 1px solid $base-lt-color;
    width: 100%;
    color: $d-color;
    transition: 0.3s;

    span {
      display: block;
      padding: 15px 10px;
    }

    &:hover {
      background-color: $base-lt-color;
      color: $l-color;
      text-decoration: none;
    }
  }

  &_banner {
    background-color: #30aae5;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      // position: absolute;
      // right: -30px;
      // bottom: 0;
      // transform: scale(0.9);
      position: absolute;
      right: -35px;
      bottom: 0;
      transform: scale(0.8);
    }
  }

  &_bg {
    background-color: rgba(130, 215, 255, 0.1);
    margin-bottom: 20px;

    .cash {
      p {
        margin-bottom: 10px;
      }
    }
  }
}

.search {
  background-color: #30aae6;
  padding: 2rem 0;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  h4 {
    margin-bottom: 0;
    font-size: $m-font;
    color: $base-color;
    font-weight: normal;
  }

  &_banner {
    background-color: #30aae6;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: -35px;
      bottom: 0;
      transform: scale(0.8);
    }
  }

  form {
    .btn {
      padding: 0.5rem 1.5rem;
      background-color: $base-color;
      border: none;
      color: white;

      &:hover {
        background-color: darken($base-color, 15%);
      }
    }

    input {
      border: 1px solid #757575;
    }

    select {
      border: 1px solid #757575;
      padding-right: 68px;

      @include lg {
        padding-right: 32px;
      }
    }
  }

  &_result_table {
    thead {
      display: none;

      tr {
        th {
          color: $base-color;
          font-weight: normal;
          border-top: none;
          border-bottom: 1px solid lighten($color: $color-4, $amount: 40%);
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $color-4;

        td {
          display: block;
          padding: 1px;
          border-top: none;

          &::before {
            content: attr(data-th);
            display: inline-block;
            width: 120px;
            color: darken($color-4, 30%);
            margin-right: 1em;
            padding: 10px;
          }
        }
      }
    }

    @include lg {
      table {
        table-layout: fixed;
      }

      thead {
        display: table-header-group;
        background-color: $color-4;
        text-align: center;

        tr {
          th {
            color: $d-color;
            border: 1px solid #dee2e6;
          }
        }
      }

      tbody {
        text-align: center;

        tr {
          td {
            padding: 0.75rem;
            display: table-cell;
            border: 1px solid #dee2e6;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.introduce {
  background-color: #30aae5;
  padding: 2rem 0;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  &_banner {
    background-color: #30aae5;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  h3 {
    font-size: $l-font;
    color: $base-color;
    font-weight: normal;
    // margin-bottom: 1rem;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
    }
  }

  table {
    // border: 1px solid $base-color;
    border-collapse: separate;
    border-spacing: 0;

    tr:nth-child(1) {
      th {
        border-top-left-radius: 0.5rem;
      }

      td {
        border-top-right-radius: 0.5rem;
      }
    }

    tr:nth-child(4) {
      th {
        border-bottom: none;
        border-bottom-left-radius: 0.5rem;
      }

      td {
        border-bottom-right-radius: 0.5rem;
        border-bottom: 1px solid $base-color;
      }
    }

    th {
      background-color: $base-color;
      color: $l-color;
      padding: 0.8rem;
      border-bottom: 1px solid $l-color;
    }

    td {
      padding: 0.8rem;
      border-top: 1px solid $base-color;
      border-right: 1px solid $base-color;
    }
  }
}

.organization {
  background-color: #30aae5;
  padding: 2rem 0;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  &_banner {
    background-color: #30aae5;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
    }
  }
}

.breadcrumb {
  flex-wrap: nowrap;
}

.ocean {
  &_banner {
    background-color: #30aae5;
    overflow: hidden;
    position: relative;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 220px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
    }
  }

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  &-img {
    border-radius: 10px;
    display: block;
    overflow: hidden;
    &-square {
      width: 250px;
      height: 160px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 15px;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 120%;
      }
    }
  }

  &-tab {
    color: #097fc2;
    font-size: 18px;
    font-weight: bold;
    position: relative;

    &:hover {
      color: darken(#097fc2, 15%);
    }

    &:not(:last-child) {
      padding-right: 15px;
      margin-right: 10px;

      @media (min-width: 450px) {
        padding-right: 30px;
        margin-right: 0;
      }

      @include md {
        padding-right: 100px;
      }

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 2px;
        height: 40px;
        background-color: #097fc2;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(20deg);
      }
    }
  }

  &-tab-wrapper {
    max-width: 700px;
    margin: 0 auto;
    -ms-flex-pack: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

.mswa {
  &_banner {
    background-color: #30aae5;
    overflow: hidden;
    position: relative;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 10%;
      transform: translateY(-50%);
      top: 50%;
    }
  }

  &_group {
    &_icon {
      width: 60px;
      height: 60px;
      background-color: #31aae5;
      border-radius: 100%;
      position: relative;
      flex: 1 0 60px;
      margin-right: 1rem;

      i {
        color: #fff;
        font-size: 24px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    &_title {
      position: relative;
      margin-bottom: 2rem;

      &::before {
        content: "";
        height: 1px;
        width: 50px;
        background-color: #9e9e9e;
        position: absolute;
        bottom: -1rem;
      }
    }

    ul {
      padding-inline-start: 20px;
    }
  }

  &_organizer {
    white-space: nowrap;

    img {
      max-height: 45px;
    }
  }

  &_news {
    background-image: url("../img/mswa/bg-1.png");
    background-repeat: no-repeat;
    background-position: center right;
    &_link {
      display: flex;
      border-top: 1px solid $base-lt-color;
      width: 100%;
      color: $d-color;
      transition: 0.3s;

      span {
        display: block;
        padding: 15px 10px;
        flex-shrink: 1;
      }

      &:hover {
        background-color: $base-lt-color;
        color: $l-color;
        text-decoration: none;
      }
    }
  }

  &_review {
    &_video {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      width: 250px;
      min-height: 150px;
      max-width: 100%;
      background-color: #fff;
      display: block;
      img {
        position: absolute;
        max-width: 250%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        opacity: 0.8;

        @include lg {
          max-width: 200%;
        }

        @include xl {
          max-width: 150%;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &::before {
        content: "";
        background-image: url("../img/mswa/youtube-play.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 54px;
        height: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 1;
      }

      &_modal {
        button {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &_body {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &_close {
        width: 50px;
        height: 50px;
        position: absolute;
        top: -25px;
        right: -25px;
        z-index: 1;
        background-color: $base-color;
        border-radius: 15px;

        &::after,
        &::before {
          content: "";
          width: 60%;
          height: 2px;
          position: absolute;
          // transform-origin: 0 0;
          background-color: #fff;
          top: 50%;
          left: 50%;
        }
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }
    }

    &_list {
      width: 100%;

      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid rgba($color: $base-color, $alpha: 0.5);
          }
        }
      }

      a {
        position: relative;
        display: block;
        width: 100%;
        color: $d-color;
        padding: 0.5rem 0.25rem;

        &:hover {
          color: $d-color;
        }
      }

      .content-download-file-icon {
        position: absolute;
        right: 0;
      }
    }
  }

  h4 {
    color: $base-color;
  }

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }
  &_table {
    thead {
      // display: none;

      tr {
        th {
          color: $base-color;
          font-weight: normal;
          border-top: none;
          border-bottom: 1px solid lighten($color: $base-color, $amount: 40%);

          &:nth-child(1) {
            width: 100px;
          }

          &:nth-child(2) {
            width: 250px;
          }

          &:nth-child(3) {
            width: 200px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid lighten($color: $base-color, $amount: 40%);

        td {
          // display: flex;
          // align-items: baseline;
          // display: block;
          position: relative;
          // padding: 5px 10px 5px 130px;
          // padding-left: 100px;
          border-top: none;
          a {
            width: 100%;
            display: block;
            text-decoration: none;
            color: #333;
            &:hover {
              color: #333;
              text-decoration: underline;
            }
          }
          // &::before {
          //   content: attr(data-th);
          //   display: block;
          //   width: 125px;
          //   min-width: 120px;
          //   color: $base-color;
          //   margin-right: 1em;
          //   padding: 5px 10px;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          // }
        }
      }
    }

    @include lg {
      thead {
        display: table-header-group;
      }

      tbody {
        tr {
          td {
            padding: 0.75rem;
            display: table-cell;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

#origin {
  padding: 30px 0 54px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../img/ocean/stock-photo--empty-sand-beach-in-front-of-summer-sea-with-copy-space-1445014490.jpg")
      no-repeat bottom center / cover;
    z-index: -1;
    opacity: 0.3;
  }

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }
}

#coast {
  padding: 56px 0;
  background-color: #f2fbff;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    background: url("../img/ocean/bg-2.png") no-repeat center center/contain;
    width: 250px;
    height: 120px;
    position: absolute;
    bottom: 0;
    right: -40px;
    transform: scaleX(-1);
    opacity: 0.2;
  }

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }
}

#danger {
  padding: 56px 0;
  background: url("../img/ocean/bg-3.jpg") no-repeat bottom right/ cover;

  hr {
    border-top: 1px solid white;
    margin: 1rem 0 2rem;
  }

  .table {
    thead {
      display: none;

      @include md {
        display: table-header-group;
      }

      th {
        color: #097fc2;

        &:not(:first-child) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        th,
        td {
          display: block;

          @include md {
            display: table-cell;
          }
        }

        th {
          background-color: #fff;
          color: #097fc2;

          @include md {
            background-color: transparent;
            color: white;
          }
        }

        td {
          position: relative;
          padding-left: 200px;

          &::before {
            content: attr(data-place);
            display: block;
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
          }

          @include md {
            padding-left: 0.75rem;
            text-align: center;

            &:before {
              content: "";
            }
          }
        }
      }
    }
  }
}

#protect {
  padding: 56px 0;
  background-color: #f2fbff;
  position: relative;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }

  &:before {
    @include md {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: -20px;
      width: 185px;
      height: 96px;
      background: url("../img/ocean/bg-3.png") no-repeat center center/contain;
      opacity: 0.2;
      transform: scaleX(-1);
    }

    hr {
      border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
      margin: 1rem 0 2rem;
    }
  }

  .timeline {
    &-card {
      position: relative;

      &:before {
        @include md {
          content: "";
          display: block;
          position: absolute;
          transform: translateY(-50%);
          border-width: 8px;
          border-style: solid;
          top: 28px;
        }
      }

      &:after {
        @include md {
          content: "";
          display: block;
          position: absolute;
          top: 28px;
          width: 2px;
          height: calc(100% - 3rem);
          background-color: #097fc2;
        }
      }

      &.left {
        &:before {
          right: -12px;
          border-color: transparent transparent transparent #097fc2;
        }

        &:after {
          right: -16px;
        }

        .timeline-card-title {
          &:after {
            right: -18px;
          }
        }
      }

      &.right {
        &:before {
          left: -12px;
          border-color: transparent #097fc2 transparent transparent;
        }

        &:after {
          left: -16px;
        }

        .timeline-card-title {
          &:after {
            left: -18px;
          }
        }
      }

      &.none {
        &:after {
          display: none;
        }
      }

      &-title {
        padding: 1rem;
        background-color: #097fc2;
        position: relative;

        &:after {
          @include md {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: #097fc2;
            width: 6px;
            height: 6px;
          }
        }

        span {
          color: white;
        }
      }

      &-body {
        padding: 1rem;
        background-color: #fff;
      }

      &-link {
        vertical-align: middle;
        color: #1a87c1;

        &:hover {
          color: darken(#1a87c1, 20%);
        }

        img {
          margin-right: 0.5rem;
          width: 16px;
          height: 18px;
        }

        .fa-youtube {
          color: #ff1714;
        }
      }
    }
  }
}

#link {
  padding: 56px 0;
  background-color: #e6faff;

  hr {
    border-top: 1px solid rgba($color: $base-color, $alpha: 0.5);
    margin: 1rem 0 2rem;
  }
}

.health {
  &_banner {
    background-color: #30aae5;
    margin-bottom: 30px;

    h2 {
      color: $l-color;
      letter-spacing: 10px;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;

      @include md {
        padding: 75px 0;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
      width: 200px;
      height: 148px;
    }
  }
}

.text-base {
  color: $base-color;
}
.img-pdf {
  margin-right: 0.5rem;
  width: 16px;
  height: 18px;
}

.font-24 {
  font-size: 1.5rem;
}

#lightbox {
  display: flex;
  flex-direction: column-reverse;
}

.lb-close {
  position: relative;
  bottom: 8px;
}

.text-pink {
  color: #e5806c;
}

.link-blue {
  color: #1987c1;
  &:hover {
    color: darken(#1987c1, 10%);
    text-decoration: none;
  }
}
