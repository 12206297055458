.gotop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #1987c1;
  color: #fff;
  line-height: 90px;
  text-align: center;
  border-radius: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    color: white;
  }

  i {
    font-size: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.show {
    opacity: 0.5;
    visibility: visible;

    &:hover,
    &:active {
      opacity: 1;
      text-decoration: none;

      i {
        color: white;
      }
    }
  }
}

.btn-blue {
  color: #fff!important;
  display: inline-block;
  margin: 1rem 0.5rem;
  padding: 0.5rem 2rem;
  background-color: $base-color;
  border-radius: 5px;

  &:hover {
    background-color: darken($base-color, 10%);
    color: #fff;
    text-decoration: none;
  }
}

.btn-outline-blue {
  display: inline-block;
  margin: 1rem 0.5rem;
  padding: 0.5rem 2rem;
  color: $base-color;
  border: 1px solid $base-color;
  border-radius: 5px;

  &:hover {
    background-color: $base-color;
    color: #fff;
    text-decoration: none;
  }
}