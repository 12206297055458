// ========== 3rd Party SCSS (三方css引入)
// @import "bootstrap";
// @import "./node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "./node_modules/@fortawesome/fontawesome-free/scss/brands";
// @import "./node_modules/@fortawesome/fontawesome-free/scss/regular";
// @import "./node_modules/@fortawesome/fontawesome-free/scss/solid";
// @import "./node_modules/@fortawesome/fontawesome-free/scss/v4-shims";
// ========== Plugins (全站引入)

// ========== 共同
@import "version/_common"; // -------- 函數值
@import "version/_module"; // -------- 客製化模組
@import "version/_header"; // -------- header
@import "version/_footer"; // -------- footer
@import "version/_title"; // -------- title
@import "version/_nav"; // -------- 巡覽列
@import "version/_button"; // -------- 按鈕
@import "version/_jumbotron"; // -------- 主視覺
@import "version/_animation"; // -------- 動態
@import "version/_content"; // -------- 不分類共用內容
@import "version/_table"; // -------- 表格
@import "version/_icon"; // -------- 標誌
@import "version/_list"; // -------- 項目符號
@import "version/_tabs"; // -------- 頁籤
@import "version/_carousel"; // -------- 輪播
@import "version/_form"; // -------- 表格
@import "version/_accroding"; // -------- 風琴

// ========== 各別版型設計 (請自行新增)
@import "layout/_index"; // -------- 首頁

// ========== 媒體查詢
@import "version/_media"; // -------- mediaquery
@import "version/_print"; // -------- 列印樣式修正

//========== IE 視覺修正
@import "mainfix/ie8"; // -------- ie8
@import "mainfix/ie9"; // -------- ie9
@import "mainfix/IE_fix"; // -------- ie10以上修正
@import "mainfix/browser_fix"; // -------- 其他瀏覽器修正
