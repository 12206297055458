.main_title {
  margin-bottom: 0;
  font-size: $l-font;
  color: $base-color;
  font-weight: 400;
  span {
    font-weight: 100;
    margin-right: 10px;
  }
}
h4 {
  font-size: $m-font;
}

ul,ol {
  padding-left: 20px;
}
